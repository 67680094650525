import React from "react";
import banner from "../../../../assets/bannerLogo.png";
import "./Banner.scss";
import {
  BoomIcon,
  Discord,
  Facebook,
  GitIcon,
  Instagram,
  LinkedIn,
  Reddit,
  Telegram,
  Threads,
  TwitterX,
  YouTube,
} from "../../../../assets/StoreAsset/StoreAssets";
import { ButtonCustom } from "../../../../Common/ButtonCustom/ButtonCustom";
import Path from "../../../../Routing/Constant/RoutePath";
import { Link } from "react-router-dom";

function Banner() {
  return (
    <div className="bannerSection">
      <div className="bannerSection_contant container">
        <div className="bannerSection_left">
          <h1>
            Streamline Your <br /> Digital Life with a <br />{" "}
            <span className="bannerBlueText">Future-Proof Wallet!</span>
          </h1>
          <p>
            Experience the convenience of managing your assets with Future
            Wallet. This unique all-in-one solution not only safeguards your
            investments but also facilitates the purchase, storage, and trading
            of digital assets. Moreover, it opens up diverse financial avenues
            to enhance your return on investment.
          </p>
          <div className="bannerSection_left_button">
            <Link to={Path.WALLET}>
              {" "}
              <ButtonCustom label="Explore Apps" />
            </Link>
          </div>
          <div className="bannerSection_left_links">
            {/* <Link
              target="blank"
              // to={"https://discord.com/invite/ya4gXaxc"}
            >
              {" "}
              <Discord />
            </Link> */}
            <Link
              target="blank"
              to={"https://www.youtube.com/@futurewalletofficial"}
            >
              {" "}
              <YouTube />
            </Link>
            {/* <Link
              target="blank"
              // to={"https://www.linkedin.com/in/future-group"}
            >
              {" "}
              <LinkedIn />
            </Link> */}
            <Link
              target="blank"
              to={"https://www.instagram.com/futurewalletofficial/"}
            >
              <Instagram />
            </Link>
            {/* <Link
              target="blank"
              // to={"https://www.reddit.com/r/FutureGroup"}
            >
            
              <Reddit />
            </Link> */}
            <Link target="blank" to={"https://t.me/futurewalletofficial"}>
            
              <Telegram />
            </Link>
            <Link
              target="blank"
              to={"https://facebook.com/futurewalletofficial"}
            >
            
              <Facebook />
            </Link>
            <Link target="blank" to={"https://x.com/MyfWallet"}>
            
              <TwitterX />
            </Link>
            <Link
              target="blank"
              to={"https://www.threads.net/@futurewalletofficial"}
            >
            
              <Threads />
            </Link>
          </div>
        </div>

        <div className="bannerSection_right">
          <img src={banner} alt="banner" />
        </div>
      </div>
    </div>
  );
}

export default Banner;
