import React from "react";
import "./AboutUsSec.scss";
import phoneImg from "../../assets/homeAboutImg.png";
import EasyManage from "../../assets/EasyManage.svg";
import FastProcessing from "../../assets/FastProcessing.svg";
import Professional from "../../assets/Professional.svg";
import Business from "../../assets/Business.svg";
import CommonAboutus from "../../Common/CommonAboutus/CommonAboutus";

function AboutUsSec() {
  const cardData = [
    {
      src: EasyManage,
      title: "Easy to Manage",
      description: "At the heart of Future Wallet lies a simple and intuitive design, empowering users of diverse backgrounds to explore the digital asset realm hassle-free. The cross-platform accessibility has simplified the portfolio management process.",
    },
    {
      src: FastProcessing,
      title: "Fast Processing",
      description: "The wallet supports ultra-fast transaction processing to ensure that crypto activities are conducted quickly and reliably. Explore the remarkable potential of Blockchain technology through an interface that prioritizes efficiency and responsiveness.",
    },
    {
      src: Professional,
      title: "Highly Professional",
      description: "Future wallet sets a new standard by offering enterprise-graded solutions. Trust our relentless commitment to innovation, security, and user experience as you unravel the opportunities presented by the Web3 domain.",
    },
    {
      src: Business,
      title: "Protect Your Business",
      description: "Businesses can secure their assets by leveraging our non-custodial architecture that provides unrivaled control and security. Our solutions are integrated with top-tier security mechanisms to help you embrace your financial future confidently.",
    },
  ];
  return (
    <div className="aboutSec container">
      <div className="aboutSec_left">
        <img src={phoneImg} alt="" />
      </div>
      <div className="aboutSec_right">
        <CommonAboutus
          subTitle="Creating an Accessible"
          subHeading="& Equitable Financial World!"
          paragraph="Crypto offers economic freedom by fostering participation in the global economy. Future Wallet has taken the plunge to provide these opportunities to every individual worldwide. We are changing the face of age-old financial systems by providing a reliable platform that streamlines users' engagement with assets. From safekeeping and trading to swift cross-border transfers, we are unearthing possibilities within the digital finance ecosystem."
          cardData={cardData}
          cardsec={true}
        />
      </div>
    </div>
  );
}

export default AboutUsSec;
