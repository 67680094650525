import React from "react";
import FutureDisc from "./walletComponnets/FutureDisc";
import "./wallet.scss";
import MobileGroups from "./walletComponnets/MobileGroups";
import FeaturesWallet from "./walletComponnets/FeaturesWallet";
import ForQueriesComponent from "../../Common/ForquriesComponent/ForQueriesComponent";
import EasyManage from "../../assets/EasyManage.svg";
import FastProcessing from "../../assets/FastProcessing.svg";
import Professional from "../../assets/Professional.svg";
import WalletBanner from "./WalletBanner/WalletBanner";
import PeerImg from "../../assets/peerImg.png";
import Handholding from "../../assets/handholding.png";
import Accountimg from "../../assets/accountimg.png";
import DecodingImg from "../../assets/DecodingImg.png";
import leftFace from "../../assets/leftFace.png";
import CryptoDynastyImg from "../../assets/CryptoDynastyImg.png";
import futureWallet from "../../assets/futureWallet.png";
function Wallet() {
  const cardData = [
    {
      src: EasyManage,
      title: "DApp Browser",
      description:
        "The platform consists of an in-built browser for swift Dapps accessed through the wallet interface.",
    },
    {
      src: FastProcessing,
      title: "Multi-Wallet Support:",
      description:
        " You can manage multiple wallets(up to 10 wallets) within the same app by adding cryptos and setting up settings according to your preferences.",
    },
    {
      src: Professional,
      title: "Highly Professional",
      description:
        "Users can add and manage custom tokens beyond native digital assets supported by the wallet.",
    },
    // {
    //     src: Business,
    //     title: "Custom Token Support: ",
    //     description: "Totam rem aperiam, eaque ipsa quae ab illo",
    // },
  ];

  const cardData2 = [
    {
      src: PeerImg,
      head: "High-Graded Security Features:",
      desc: " The platform is fostered with advanced security features to protect assets from hacks and breaches. ",
    },
    {
      src: Handholding,
      head: "Cross & On-Chain Swaps:",
      desc: "The platform can be used to conduct cross-chain swaps integrated with 1inch dex APIs while the cross-chain swaps include SWFT Bridge APIs.",
    },
    {
      src: Accountimg,
      head: "Portfolio Tracking: ",
      desc: "Portfolio tracking gives a complete overview of owned assets and their values and measures their performance over time.",
    },
    {
      src: Accountimg,
      head: "Multi-Chain Support:",
      desc: "The wallet supports prominent blockchains, including Bitcoin, Ethereum, Binance Smart Chain & Tron.",
    },
  ];

  const cryptodansity = [
    {
      description:
        "Fiat On/Off ramp integrations offer complete control over the decentralized economy and the freedom to transact on your terms.",
    },
    {
      description:
        " This serves as a gateway between bridge traditional and decentralized finance.",
    },
    {
      description:
        "The solution supports traditional currency conversions into various digital assets.",
    },
    {
      description: "All through the convenience of your Future wallet account",
    },
  ];
  return (
    <div className="wallet_page">
      <div className="container">
        <WalletBanner />
        <MobileGroups />
        <FutureDisc />
        <FeaturesWallet
          subTitle=" Spotlighting the Remarkable Basic Features"
          // subHeading="cnjdcjdb csd"
          paragraph="Future Wallet consists of basic features and functionalities impregnated to form secure, convenient, and customizable user experiences, making it an indispensable tool for managing the digital asset realm."
          reverse="true"
          src={futureWallet}
          listing
        />
        <FeaturesWallet
          subTitle=" Decoding the Intermediate Features"
          paragraph="Future wallets have evolved significantly to offer intermediate features that cater to users' diverse needs. These intermediate features go beyond basic transaction capabilities and provide enhanced functionality, security, and convenience. "
          cardData={cardData}
          cardsec={true}
          src={DecodingImg}
        />
        <FeaturesWallet
          subTitle="Navigating the Exceptional Advanced Wallet Features"
          paragraph="Explore the state-of-the-art features of the Future wallet, which is integrated for enhanced user experiences. From high-graded security to multi-chain support, the platform offers unrivaled functionality to streamline digital asset management.
"
          cardData2={cardData2}
          cardsec2={true}
          reverse="true"
          src={leftFace}
        />
        <FeaturesWallet
          subTitle="Fiat On/Off Ramps with Future Wallet: The Gateway to Crypto Dynasty"
          cardData={cryptodansity}
          cardsec={true}
          src={CryptoDynastyImg}
        />

        <ForQueriesComponent />
      </div>
    </div>
  );
}

export default Wallet;
