//store.ts

import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import storage from "redux-persist/lib/storage";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistStore,
  persistReducer,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import { contactUsAPI } from "./Services/SettingsAPI";
import userSlice from "./Slices/user.slice";
import toastSlice from "./Slices/toast.slice";
const persistConfig = { key: "root", storage };

const appReducer = combineReducers({
  user: userSlice,
  toast: toastSlice,
  [contactUsAPI.reducerPath]: contactUsAPI.reducer,
});

const persistedReducer = persistReducer(persistConfig, appReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      contactUsAPI.middleware
    ),
});

export const persistor = persistStore(store);

setupListeners(store.dispatch);
