import { toastState } from "../../Utility/Slices/toast.slice";
import Toaster from "../Toast/Toast";

export const headerConfig = (headers, getState) => {
  const token = getState().user?.users?.jwt_token;

  if (token) {
    headers.set("authorization", token);
  }
  headers.set("Content-Type", "application/json");
  return headers;
};

export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
  return true;
};

export function throttle(mainFunction, delay) {
  let timerFlag = null;

  return (...args) => {
    if (timerFlag === null) {
      mainFunction(...args);
      timerFlag = setTimeout(() => {
        timerFlag = null;
      }, delay);
    }
  };
}

export const calculatePasswordStrength = (password) => {
  var regex = [
    "[A-Z]", // Uppercase Alphabet.
    "[a-z]", // Lowercase Alphabet.
    "[0-9]", // Digit.
    "[$@$!%*#?&]", // Special Character.
  ];

  var passed = 0;

  for (var i = 0; i < regex.length; i++) {
    if (new RegExp(regex[i]).test(password)) {
      passed++;
    }
  }

  return (passed / regex.length) * 100;
};

export const handleResponse = async (show, res, dispatch) => {
  console.log("RESSSSSSSSSS ", res);
  console.log("RESSSSSSSSSS status", res?.status);

  let isSuccess = false;
  let message = "";
  if (res?.status == 400 || res?.status == 401) {
    console.log("RESSSSSSSSSS 400 ", res?.data?.message);

    message = res?.data?.message;
    isSuccess = false;//!res?.data?.status;
  } else if (res?.status == 200 || res?.status) {
    console.log("RESSSSSSSSSS 200 ", res?.data?.message);

    isSuccess = true//!res?.error;
    message = res?.message;
  }
  // else if (res?.status == 400 || res?.status == 401) {
  //   console.log("RESSSSSSSSSS 400 ", res?.data?.message);

  //   message = res?.data?.message;
  //   isSuccess = true;//!res?.data?.status;
  // } 
  else {
    message = "Something went wrong";
    isSuccess = false;
  }
  let payload = {
    show: show && res?.status != 401,
    status: isSuccess,
    message: message,
    statusCode: res?.status,
  };
  show && Toaster(payload?.status, payload?.message);
  dispatch(toastState(payload));
};

export const formatNumber = (value, place) => {
  const numValue = parseFloat(value);
  if (isNaN(numValue)) {
    return "0.00";
  }
  const hasDecimal = numValue % 1 !== 0;
  if (hasDecimal) {
    return numValue.toFixed(place);
  } else {
    return numValue.toString();
  }
};
