import { Button } from "antd";
import React from "react";
import "./ButtonCustom.scss";

export const ButtonCustom = (props) => {
    const {
        customClass,
        leftIcon,
        label,
        rightIcon,
        title,
        disabled,
        onClick,
        style,
        htmlType,
        btnBorder,
    } = props;
    return (
        <Button
            style={style}
            className={`btnCustom ${btnBorder ? "btnBorder" : ""} ${customClass} : ""
      }`}
            onClick={onClick}
            disabled={disabled}
            htmlType={htmlType && htmlType}
            title={title ? title : ""}
        >
            {leftIcon && <span className="leftIcon">{leftIcon}</span>}
            {label && label}
            {rightIcon && <span className="rightIcon">{rightIcon}</span>}
        </Button>
    );
};
