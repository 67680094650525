// Routing paths
const Path = {
  HOME: "/",
  WALLET: "/wallet",
  FUTUREWALLET: "/privacy-policy",
  CONTACTUS: "/contact-us",
  ABOUT: "/about",
};

export default Path;
