import React from 'react'
import "./contract.scss";
function ContractDetails({ src, head, desc }) {
    return (
        <div className='contractDetails'>
            <ul>
                <li>
                    <img src={src} />
                    <div>
                        <h4>{head}</h4>
                        <p>{desc}</p>
                    </div>
                </li>
            </ul>
        </div>
    )
}

export default ContractDetails