import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { handleResponse, headerConfig } from "../../Common/functions/comman";
import { URL } from "../../Constant/Constant";

export const contactUsAPI = createApi({
    reducerPath: "/settings",
    baseQuery: fetchBaseQuery({
        baseUrl: URL.API_URL,
        prepareHeaders: (headers, { getState }) => {
            return headerConfig(headers, getState);
        },
    }),
    endpoints: (builder) => ({
        contactUsDataApi: builder.mutation({
            query: (payload) => ({
                url: "save_contact_data",
                method: "POST",
                body: payload,
            }),
            async onQueryStarted({ }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    console.log("contactUsAPI resp:: ", data);
                    const resp = data?.data;

                    handleResponse(true, data, dispatch);
                    return data;
                } catch (error) {
                    handleResponse(true, error.error, dispatch);
                    return error;
                }
            },
        }),
    }),
});

export const { useContactUsDataApiMutation } = contactUsAPI;
