import React from 'react'
import { NavLink } from 'react-router-dom'

function HeaderRight({ onLinkClick }) {
    const navLinks = [
        { to: '/', text: 'Home' },
        { to: '/wallet', text: 'Wallet' },
        { to: '/about', text: 'About' },
        { to: '/contact-us', text: 'Contact Us' },
        { to: '/privacy-policy', text: 'Privacy Policy' }
    ]

    return (
        <>
            {navLinks.map((link, index) => (
                <NavLink key={index} onClick={onLinkClick} to={link.to}>
                    {link.text}
                </NavLink>
            ))}
        </>
    )
}

export default HeaderRight

