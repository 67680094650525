import React from "react";
import { ButtonCustom } from "../ButtonCustom/ButtonCustom";
import "./ForQueries.scss";
import ForQuriesImg from "../../assets/sSDSD.png";
import { useNavigate } from "react-router-dom";
import Path from "../../Routing/Constant/RoutePath";
function ForQueriesComponent() {
  const navigate = useNavigate();
  return (
    <div className="forQuries">
      <div className="container">
        <div className="forQuries__bg">
          <div className="forQuries__bg__left">
            <img src={ForQuriesImg} alt="Icon" />
          </div>
          <div className="forQuries__bg__right">
            <div>
              <h2>For Queries</h2>
              <p>
                Get complete asset authority for the game-changing Future
                wallet. The solution empowers you to store your cryptos,
                navigate boundless DeFi domains, and unlock opportunities for
                financial freedom. Let Future Wallet be your trusted companion
                as you embark on a journey of economic freedom.
              </p>
              <ButtonCustom label="Contact" style={{ width: "156px" }} onClick={()=> navigate(Path.CONTACTUS)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForQueriesComponent;
