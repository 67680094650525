import React from "react";
import "./CommonCard.scss";

function CommonCard({title,description,src}) {
  return (
    <div className="commonCard">
      <div className="commonCard_top">
        <img src={src} alt="" />
        <h6>{title}</h6>
      </div>
      <p>{description}</p>
    </div>
  );
}

export default CommonCard;
