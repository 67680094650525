import React from "react";
import "./About.scss";
import "../FutureWallet/FutureWallet.scss";


const About = () => {
  return (
    <>
      <div className="about">
        <div className="about_banner">
          <div class="overlay"></div>

          <div className="about_banner_content container">
            <h2>
              Privacy Policy for <br /> Future Wallet
            </h2>
            <p>
              Thank you for choosing Future Wallet. This Privacy Policy
              describes how we collect, use, and disclose your personal
              information as you access our website and procure our services.
            </p>
          </div>
        </div>
        <div className="about_content">
          <div className="container">
            <h6>Effective Timeline: April 2024 to April 2025]</h6>
            <h4>Information We Collect</h4>
            <ul>
              <li>
                <p>
                  <b>Personal Information:</b> When you sign up for an account
                  or use our services, we may collect personal information such
                  as your name, email address, phone number, and billing
                  information.
                </p>
              </li>
              <li>
                <p>
                  <b>Usage Information:</b> We may collect information about how
                  you interact with our website and services, including your IP
                  address, device information, and browsing history.
                </p>
              </li>{" "}
              <li>
                <p>
                  <b>Cookies:</b> We leverage cookies and other tracking
                  technologies to enrich your experience on the site and for
                  analytics purposes.
                </p>
              </li>
            </ul>
            <h4>How We Use Your Information</h4>
            <ul>
              <li>
                <p>
                  <b>Provide Services:</b> We use your information to provide
                  you with our services, process transactions, and communicate
                  with you.
                </p>
              </li>
              <li>
                <p>
                  <b>Improve Services:</b> We analyze usage data to improve our
                  website and services, personalize content, and offer tailored
                  recommendations.
                </p>
              </li>
              <li>
                <p>
                  <b>Marketing:</b> With your permission, we may send you
                  promotional emails & updates of products & services.
                </p>
              </li>
            </ul>
            <h4>Data Security</h4>
            <p>
              By adopting safety measures, we safeguard your personal
              information from unauthorized access, disclosure, alteration, or
              destruction.
            </p>
            <h4>Data Sharing</h4>
            <p>
              We may share your personal information with trusted third parties
              for purposes such as payment processing, analytics, and marketing.
              We ensure that these third parties adhere to privacy practices
              that are consistent with this policy.
            </p>
            <div className="about_content_bottom">
              <h5>Your Choices</h5>
              <p>
                You can update your account information and communication
                preferences at any time. You may also opt out of receiving
                marketing communications from us.
              </p>
              <h5>Changes to this Policy</h5>
              <p>
                We update our privacy policy regularly. Our team will update you
                about the changes via email or website.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
