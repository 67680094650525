import React from "react";
import "../../AboutUsSec/AboutUsSec.scss";
import CommonAboutus from "../../../Common/CommonAboutus/CommonAboutus.jsx";
function FeaturesWallet({
  cardData,
  cardsec,
  reverse,
  cardData2,
  cardsec2,
  subTitle,
  src,
  subHeading,
  listing,
  paragraph,
}) {
  return (
    <div className={`aboutSec container ${reverse ? "reverse" : ""}`}>
      <div className="aboutSec_right">
        <CommonAboutus
          subTitle={subTitle}
          subHeading={subHeading}
          paragraph={paragraph}
          cardData={cardData}
          cardData2={cardData2}
          cardsec={cardsec}
          cardsec2={cardsec2}
        />
        {listing && (
          <ul>
            <li>
              {" "}
              <p>
                Future Wallet has expanded its ecosystem by introducing crypto
                debit and credit cards.{" "}
              </p>
            </li>
            <li>
              {" "}
              <p>
                The card gives users the freedom to convert currencies and
                perform transactions as per their conditions.
              </p>
            </li>
            <li>
              {" "}
              <p>
                Leverage unprecedented financial flexibility of spending cryptos
                across millions of merchants globally.
              </p>
            </li>
            <li>
              {" "}
              <p>
                Enjoy a new level of working and growing within the digital
                domain.
              </p>
            </li>
          </ul>
        )}
      </div>
      <div className="aboutSec_left">
        <img src={src} alt="" />
      </div>
    </div>
  );
}

export default FeaturesWallet;
