import React from "react";
import "./CustomerCountDetail.scss";

function CustomerCountDetail() {
  return (
    <div className="consumerOuterContainer">
    <div className=" container">
      <div className="customer">
        <div className="customer_inner">
          <h2 className="bannerBlueText">11Lacs+</h2>
          <p>
            Happy <br />
            Customer
          </p>
        </div>
        <div className="customer_inner">
          <h2 className="bannerBlueText">Over $10 million</h2>
          <p>
          Transactions
          </p>
        </div>

        <div className="customer_inner">
          <h2 className="bannerBlueText">82+</h2>
          <p>
            Countries <br />
            Covered
          </p>
        </div>
        <div className="customer_inner">
          <h2 className="bannerBlueText">12+</h2>
          <p>
            Years <br />
            Experience
          </p>
        </div>
      </div>
    </div>
    </div>
  );
}

export default CustomerCountDetail;
