import React from "react";
import "./HomeDiscription.scss";
import Comma from "../../../assets/Commas.svg";

function HomeDiscription({ activeIndex }) {
  const paragraphs = [
    "As a small enterprise owner exploring the diverse crypto segment, Future Wallet has been a valuable tool. Its seamless integration, instant processing, and high-end security mechanisms have helped me manage my assets securely and enjoy the expanding Web3 ecosystem.",
    "Future Wallet has become a trusted companion for me in the crypto-verse. It provides multi-chain capabilities and ultra-fast transaction processes, making it a versatile and convenient tool for navigating the decentralized realm. All in all, it is a reliable choice that helps you explore the expanding decentralized realm.",
    "Future Wallet has set a new standard for a business-graded crypto wallet. Its matchless security, customization capabilities, and commitment to innovation have empowered our firm and financial future. I highly recommend Future Wallets for storing assets and accessing Web3 initiatives.",
  ];

  return (
    <div className="homeDiscription">
      <img src={Comma} alt="" />
      <p>{paragraphs[activeIndex]}</p>
    </div>
  );
}

export default HomeDiscription;
