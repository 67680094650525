import React from "react";
import "./Home.scss";
import Banner from "./HomeComponets/Banner/Banner";
import OurServices from "../OurServices/OurServices";
import ForQueriesComponent from "../../Common/ForquriesComponent/ForQueriesComponent";
import CustomerCountDetail from "./HomeComponets/CustomerCountDetail/CustomerCountDetail";
import RoundCarausel from "../../Common/RoundCarausel/RoundCarausel";
import AboutUsSec from "../AboutUsSec/AboutUsSec.jsx";

function Home() {
  return (
    <div className="home">
      <Banner />
      <AboutUsSec />
      <OurServices />
      <CustomerCountDetail />
      <RoundCarausel />
      <ForQueriesComponent />
    </div>
  );
}

export default Home;
