import React, { useState } from "react";
import "./RoundCarausel.scss";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import image1 from "../../assets/Polygon1.png";
import image2 from "../../assets/Polygon2.png";
import image3 from "../../assets/Polygon3.png";
import HomeDiscription from "../../pages/Home/HomeComponets/HomeDiscription";

const RoundCarausel = () => {
  const [activeIndex, setActiveIndex] = useState(1);
  const [cards, setCards] = useState([0, 1, 2]);
  const imageUrls = [image1, image2, image3];
  const handleLeftArrowClick = () => {
    setActiveIndex((prevIndex) => (prevIndex === 0 ? 2 : prevIndex - 1));
    rotateCards("left");
  };

  const handleRightArrowClick = () => {
    setActiveIndex((prevIndex) => (prevIndex === 2 ? 0 : prevIndex + 1));
    rotateCards("right");
  };
  const rotateCards = (direction) => {
    setCards((prevCards) => {
      const rotatedCards = [...prevCards];
      if (direction === "left") {
        const lastCard = rotatedCards.pop();
        rotatedCards.unshift(lastCard);
      } else if (direction === "right") {
        const firstCard = rotatedCards.shift();
        rotatedCards.push(firstCard);
      }
      return rotatedCards;
    });
  };

  return (
    <div className="roundedCR">
      <div>
        <h1>What Our Clients Say?</h1>
      </div>
      <div className="carousel-container container">
        <div className="ui-cards">
          <CaretLeftOutlined onClick={handleLeftArrowClick} />
          {cards.map((index) => (
            <div
              className={`ui-cards_bg ${
                activeIndex === index ? "activebg" : ""
              }`}
            >
              <img
                key={index}
                className={`inactiveCarausel ${
                  activeIndex === index ? "activeCarausel" : ""
                }`}
                src={imageUrls[index]}
                alt=""
              />
            </div>
          ))}
          <CaretRightOutlined onClick={handleRightArrowClick} />
        </div>

        <HomeDiscription activeIndex={activeIndex} />
      </div>
    </div>
  );
};

export default RoundCarausel;
