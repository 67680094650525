import React from "react";
import GroupPhones from "../../../assets/groupPhones.png";
import Android from "../../../assets/Android.png";
import { Link } from "react-router-dom";
function MobileGroups() {
  return (
    <div className="group_phones">
      <img className="cellphone" src={GroupPhones} alt="phonGroup" />
      <div>
        <Link to="https://drive.google.com/file/d/13WWvHM3vfBFkhl_jI8sJrezGcvCwjaEH/view?usp=sharing" target="blank">
          <img src={Android} alt="Android" />
        </Link>
      </div>
      <p className="noteText"><b>*Note : </b><ul><li> Once the application is live on playstore, kindly uninstall the downloaded version and re-install it from Stores. <br /> Save your passphrase before uninstalling the application.</li></ul> </p>
    </div>
  );
}

export default MobileGroups;
