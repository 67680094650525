import React from "react";
import "./CommonAboutus.scss";
import CommonCard from "../CommonCard/CommonCard";
import ContractDetails from "../ContarctDetails/ContractDetails";

function CommonAboutus({
  title,
  subTitle,
  paragraph,
  subHeading,
  cardData,
  cardsec,
  cardData2,
  cardsec2,
}) {
  return (
    <div className="commonAboutbar">
      <h5>{title}</h5>
      <h1>
        {subTitle.split("<br />").map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}
        <span className="bannerBlueText">{subHeading}</span>
      </h1>
      <p>{paragraph}</p>
      {cardsec ? (
        <>
          <h6>CONTRACT PRODUCTS</h6>

          <div className="aboutCards">
            {cardData.map((card, index) => (
              <CommonCard
                key={index}
                src={card.src}
                title={card.title}
                description={card.description}
              />
            ))}
          </div>
        </>
      ) : (
        ""
      )}

      {cardsec2 ? (
        <>
          <h6>CONTRACT PRODUCTS</h6>

          <div className="productmange">
            {cardData2.map((cardDetail, index) => (
              <ContractDetails
                key={index}
                src={cardDetail.src}
                head={cardDetail.head}
                desc={cardDetail.desc}
              />
            ))}
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default CommonAboutus;
