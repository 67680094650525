import React from "react";
import { Input } from "antd";

export const InputCustom = (props) => {
  const { ...rest } = props;
  return (
    <div className="customInput">
      <Input {...rest} />
    </div>
  );
};
