import React from "react";

function FutureDisc() {
  return (
    <div className="desc_feature">
      <h2>
        About <span> Future wallet</span>
      </h2>
      <p>
        Future Wallet isn't just another crypto wallet but comprises a global
        community of tech innovators dedicated to providing democratic access to
        a decentralized world. The goal is to empower users by revolutionizing
        the internet and global economy via the principles of consent, privacy,
        and collaboration. The wallet supports multiple cryptocurrencies,
        enables secure asset management, explores financial services, and takes
        part in the Web3 revolution. Our easy-to-navigate designs allow
        beginners and experienced users to access the solution quickly and
        confidently. Unveil the potential of decentralization and pave the way
        for an equitable financial system in the future.
      </p>
    </div>
  );
}

export default FutureDisc;
