import { createSlice } from "@reduxjs/toolkit";

// Slice
const initialState = { users: null, profile: null };
const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    // userProfile: (state, action) => {
    //   state.profile = action.payload;
    // },
  },
});

export const { userProfile } = userSlice.actions;

export default userSlice.reducer;
