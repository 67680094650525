import React, { useState } from "react";
import "./CarouselWithCards.scss";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import AddressBook from "../../../../assets/addresBook.jpg";
import Dapp from "../../../../assets/Dapp.jpg";
import Portfolio from "../../../../assets/Portfolio.jpg";
import SendReceive from "../../../../assets/Send&Receive.jpg";
import WalletCreation from "../../../../assets/WalletCreation.jpg";
import Custom from "../../../../assets/Custom.jpg";

const CarouselWithCards = () => {
  const [activeCardIndex, setActiveCardIndex] = useState(0);
  
  const slidesData = [
    {
      id: 1,

      subTitle: "Portfolio Tracking",
      description:
        "Portfolio tracking enables you to monitor the value & performance of your assets through a one-stop destination.",
      imageSrc: Portfolio,
    },
    {
      id: 2,

      subTitle: "Address Book",
      description:
        "Store and access your frequently used addresses to leverage spontaneous and efficient transactions.",
      imageSrc: AddressBook,
    },
    {
      id: 3,

      subTitle: "Send, Receive, and Transaction History",
      description:
        "The wallet allows you to transfer and conveniently track your transactions.",
      imageSrc: SendReceive,
    },
    {
      id: 4,
      subTitle: "Custom Token Addition and Asset Management",
      description:
        "Add custom tokens, manage your assets, and organize them again according to your needs.",
      imageSrc: Custom,
    },
    {
      id: 5,
      subTitle: "DApp Browser",
      description:
        "Discover and engage with decentralized applications (dApps) on Ethereum & Binance Smart Chain networks.",
      imageSrc: Dapp,
    },
    {
      id: 6,
      subTitle: "Wallet Creation and Import",
      description:
        "You can create new wallets or import existing ones through the platform.",
      imageSrc: WalletCreation,
    },
  ];

  const responsive = {
    desktop: {
      breakpoint: { max: 2500, min: 1470 },
      items: 6,
      slidesToSlide: 1 
    },

    tablet: {
      breakpoint: { max: 1470, min: 1170 },
      items: 4,
      slidesToSlide: 2 
    },
    mobile: {
      breakpoint: { max: 1170, min: 300 },
      items: 3,
      slidesToSlide: 1 
    },
  
 
  };
  return (
    <div className="cardCarousel">
     <Carousel
        responsive={responsive}
        swipeable={false}
        draggable={true}
        showDots={true}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={1500}
        keyBoardControl={true}
        customTransition="transform 300ms ease-in-out"
        transitionDuration={500}
        removeArrowOnDeviceType={["tablet", "mobile"]}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
        afterChange={(nextSlideIndex) => setActiveCardIndex(nextSlideIndex)}
      >
        
        {slidesData.map((slide, index) => (
          <div
            key={slide.id}
            className={`main_sliderinner ${
              activeCardIndex === index ? "active" : ""
            }`}
          >
            <div className="main_sliderinner__image">
              <img src={slide.imageSrc} alt="" />
            </div>
            <div className="desc">
              <h3>{slide.subTitle}</h3>
              <p>{slide.description}</p>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default CarouselWithCards;