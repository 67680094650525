import PublicRoute from './Public/Public.jsx';
import PAGES from './Pages.jsx';

const routes = [
    {
        path: '/',
        element: <PublicRoute />,
        children: PAGES().PUBLIC_PAGES,
    },
];

export default routes;
