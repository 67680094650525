import React, { useState, useEffect } from "react";
import { Col, Form, Row, Typography, Input, message } from "antd";
import { InputCustom } from "../../Common/InputCustom/InputCustom";
import { ButtonCustom } from "../../Common/ButtonCustom/ButtonCustom";
import "./ContactUs.scss";
import ReCAPTCHA from "react-google-recaptcha";
import { useContactUsDataApiMutation } from "../../Utility/Services/SettingsAPI";
import { GOOGLE_CAPTCHA } from "../../Constant/Constant";


const { Title } = Typography;
const { TextArea } = Input;

const ContactUs = () => {
  const [form] = Form.useForm(); // Define a form reference
  const recaptchaRef = React.useRef(null);
  // const recaptchaRef = React.createRef();
  // const recaptchaRef = React.useRef < ReCAPTCHA > (null);

  const [contactUsDataApi, { data, error, isLoading }] = useContactUsDataApiMutation();
  const [messageApi, contextHolder] = message.useMessage();
  const [value, setValue] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [captchaValue, setCaptchaValue] = useState("");

  useEffect(() => {
    if (data != undefined) {
      console.log("llllllll INN", data);
      // messageApi.info('payload?.message');

      form.resetFields();
      setCaptchaValue("")
      setFirstName("")
      setLastName("")
      setEmail("")
      setPhoneNumber("")
      setCompanyName("")
      setValue("")
    }
    if (error != undefined) {
      console.log("error INN");
      setCaptchaValue("")
    }
  }, [data, error])

  const onFinish = (values) => {
    console.log("Success:", values);
    onClickButton()
  };
  const onFinishFailed = (errorInfo) => {
    messageApi.info('Hello, Ant Design!');
    console.log("Failed:", errorInfo);
  };
  const onClickButton = async () => {
    if (captchaValue != "") {
      console.log("onClickButton value:-", captchaValue);
      onSubmitAPI(captchaValue)
    }
    else {
      console.log("onClickButton value:- ELSE==", captchaValue);
      recaptchaRef.current.reset();
      recaptchaRef.current.execute();
    }
  };
  function onChange(value) {
    console.log("recaptcha value change or reset:", value);
    if (value != null) {
      setCaptchaValue(value)
      onSubmitAPI(value)
    }
  }

  const onSubmitAPI = (token) => {
    console.log("--contactUsDataApi--");

    if (firstName != '' && lastName != '' && email != '' && phoneNumber != '' && companyName != '' && value != '' && token != '') {
      console.log("INSIDE API CALL== ", firstName);

      let param = {
        "first_name": firstName,
        "last_name": lastName,
        "email": email,
        "phone_number": phoneNumber,
        "company_name": companyName,
        "message": value,
        "captcha_response_key": token
      }
      console.log("param:::::", param);
      contactUsDataApi(param);
    }
  };
  const validateInput = (_, value) => {
    // Regular expression to match only numbers
    const regex = /^\+?[0-9()\s-]{7,}$/;
    if (!value) {
      return Promise.reject(new Error('Please input your phone number.'));
    }
    if (!regex.test(value)) {
      return Promise.reject(new Error('Please enter a valid phone number.'));
    }
    return Promise.resolve();
  };


  return (
    <div className="ContactUs">
      <div className="container">
        <h3>Contact Us</h3>
        <p>
          If you have any questions, please don’t hesitate to contact us. Our team is here to assist you and offer the support you require. Fill in the details in the form below, and our experts will get back to you shortly.
        </p>
        <div className="form-outer">
          <Form
            form={form}
            layout="vertical"
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Row gutter={20}>
              <Col xs={24} lg={8}>
                <Form.Item
                  label="First Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your first name!",
                      whitespace: true,
                    },
                  ]}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                >
                  <InputCustom placeholder="Enter name" />
                </Form.Item>
              </Col>
              <Col xs={24} lg={8}>
                <Form.Item
                  label="Last Name"
                  name="lastname"
                  rules={[
                    {
                      required: true,
                      message: "Please input your last name!",
                      whitespace: true,
                    },
                  ]}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                >
                  <InputCustom placeholder="Enter name" />
                </Form.Item>
              </Col>
              <Col xs={24} lg={8}>
                <Form.Item
                  label="Email Address"
                  name="Email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                  ]}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                >
                  <InputCustom placeholder="Enter email" />
                </Form.Item>
              </Col>
              <Col xs={24} lg={8}>
                <Form.Item
                  label="Phone Number"
                  name="number"
                  rules={[
                    // {
                    //   required: true,
                    //   message: "Please input your phone number!",
                    //   whitespace: true,
                    // },
                    {
                      validator: validateInput,
                    },
                  ]}
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                >
                  <InputCustom placeholder="Enter number" />
                </Form.Item>
              </Col>
              <Col xs={24} lg={8}>
                <Form.Item
                  label="Company Name"
                  name="companyname"
                  rules={[
                    {
                      required: true,
                      message: "Please input company name!",
                      whitespace: true,
                    },
                  ]}
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                >
                  <InputCustom placeholder="Enter company name" />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  label="Message"
                  name="massage"
                  rules={[
                    {
                      required: true,
                      message: "Please input message!",
                      whitespace: true,
                    },
                  ]}
                >
                  <TextArea
                    className="textAreaContact"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    placeholder="Enter here"
                    autoSize={{
                      minRows: 3,
                      maxRows: 5,
                    }}
                  />
                </Form.Item>
              </Col>

              <Col lg={24}>
                {/* <Form.Item
                // onSubmit={() => {
                //   const token = recaptchaRef.current.execute();
                //   console.log("token==", token)
                // }}
                > */}
                <ReCAPTCHA
                  ref={recaptchaRef}
                  // ref={(r) => setCaptchaRef(r)}
                  sitekey={GOOGLE_CAPTCHA.SITE_KEY}
                  onChange={onChange}
                  size="invisible"

                />
                {/* </Form.Item> */}
              </Col>

              <Col lg={24}>
                <Form.Item>
                  <ButtonCustom
                    type="primary"
                    htmlType="submit"
                    label="Submit"
                    disabled
                  // onClick={onClickButton}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;