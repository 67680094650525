import React from "react";
import Bannerwallet from "../../../assets/bannerwallet.png";

function WalletBanner() {
  return (
    <div className="walletBanner">
      <div className="walletBanner_left">
        <h2>
          Soar into the Crypto Domain with Stellar <span> Future Wallet</span>
        </h2>
        <p>
          Kickstart your crypto odyssey with Future Wallet, which is your key to
          the decentralised future. The groundbreaking solution provides users
          with complete asset authority, allowing them to store, send, and
          receive cryptos and tokens. Explore the burgeoning DeFi landscape,
          access third-party applications, and unlock new avenues for financial
          freedom. Navigate the financial future today!
        </p>
      </div>
      <div className="walletBanner__right">
        <img alt="bannering" src={Bannerwallet} />
      </div>
    </div>
  );
}

export default WalletBanner;
