import React, { useState } from "react";
import Logo from "../../assets/logo.png";
import "./Header.scss";
import useCurrentWidth from "../Hooks/useCurrentWidth.jsx";
import { Drawer, Grid } from "antd";
import HeaderRight from "../HeaderRight/HeaderRight.jsx";
import { Link } from "react-router-dom";
import Path from "../../Routing/Constant/RoutePath";
function Header() {
  const { useBreakpoint } = Grid;
  const { lg, sm } = useBreakpoint();
  const [open, setOpen] = useState(false);
  const width = useCurrentWidth();
  const [isScrolled, setIsScrolled] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > (width > 991 ? 0 : 100)) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  window.addEventListener("scroll", handleScroll);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <div className={`futureHeader ${isScrolled ? "scroll" : ""}`} id="home">
      <div className="container">
        <div className="futureHeader_Main">
          <div className="futureHeader_Main__headerlogo me-auto">
            {width > 767 ? (
              <Link to={Path.HOME}>  <img src={Logo} alt="Logo" /></Link>
            ) : (
              <Link to={Path.HOME}>  <img src={Logo} alt="Logo" /></Link>
            )}
          </div>
          <div className="futureHeader_Main__rightSec">
            {lg && <HeaderRight />}

            {!lg && (
              <>
                <div className="menuToggle" onClick={showDrawer}>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>

                <Drawer
                  placement="right"
                  onClose={onClose}
                  open={open}
                  width={sm ? "378px" : "300px"}
                  className="futureHeader_Main__rightSec"
                >
                  <HeaderRight onLinkClick={onClose} />
                </Drawer>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
