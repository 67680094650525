import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

const Toaster = (status, message) => {
  if (status) {
    return toast.success(message, {
      autoClose: 2000,
    });
  } else {
    return toast.error(message, {
      autoClose: 2000,
    });
  }
};

export default Toaster;
