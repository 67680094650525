import React from "react";
import "./OurServices.scss";
import CarouselWithCards from "../Home/HomeComponets/CarouselWithCards/CarouselWithCards";

function OurServices() {
  return (
    <div className="ourServices">
      <div className="ourServices_head">
        <h1>
          Our Comprehensive{" "}
          <span className="bannerBlueText">Features & Functionalities</span>
        </h1>
        <p>
          Future Wallet offers a comprehensive suite of services incorporated to
          meet distinct user needs. This all-in- <br />
          one platform allows you to manage assets across multiple networks in a
          hassle-free and secure way.
        </p>
      </div>
      <CarouselWithCards />
    </div>
  );
}

export default OurServices;
