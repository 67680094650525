import React from 'react'
import Logo from "../../assets/logo.png";
import { Col, Row } from 'antd';
import "./Footer.scss";
import { Discod, Youtube, Linkedin, Insta, Github, YouTube, LinkedIn, Instagram, GitIcon, BoomIcon, Facebook, Discord, Threads, Xm, Telegram, TwitterX, Reddit } from '../../assets/StoreAsset/StoreAssets';
import { Link } from 'react-router-dom';
import Path from '../../Routing/Constant/RoutePath';
import facebook from "../../assets/Facebook.svg"
function Footer() {
    return (
        <div className='footer_main'>
            <div className='container footer_main_top'>
                <Row>
                    <Col span={12} xs={24} md={12} lg={12} >
                        <div className='footer_main__left'>
                        <Link to={Path.HOME}><img src={Logo} /></Link>
                        </div>
                    </Col>
                    <Col span={12} xs={24} md={12} lg={12} >
                        <div className='footer_main__right'>
                            <h2>Get in touch</h2>
                            <div>
                            {/* <Link target='blank'
                            // to={"https://discord.com/invite/ya4gXaxc"}
                            > <Discord /></Link> */}
                            <Link target='blank'
                            to={"https://www.youtube.com/@futurewalletofficial"}
                            > <YouTube /></Link>
                            {/* <Link target='blank'
                            // to={"https://www.linkedin.com/in/future-group"}
                            > <LinkedIn /></Link> */}
                                <Link target='blank'
                                to={"https://www.instagram.com/futurewalletofficial/"}
                                ><Instagram /></Link>
                                {/* <Link target='blank'
                                // to={"https://www.reddit.com/r/FutureGroup"}
                                >  <Reddit /></Link>  */}
                                <Link target='blank'
                                to={"https://t.me/futurewalletofficial"} 
                                >  <Telegram /></Link>
                                <Link target='blank'
                                to={"https://facebook.com/futurewalletofficial"}
                                >  <Facebook /></Link>
                                <Link target='blank'
                                to={"https://x.com/MyfWallet"}
                                >  <TwitterX /></Link>
                                <Link target='blank'
                                to={"https://www.threads.net/@futurewalletofficial"}
                                >  <Threads /></Link>

                            </div>
                        </div>
                    </Col>
                </Row>

            </div>
            <div className='footer_main_copyright'>
                <p>ⒸFUTURE WALLET 2024. All rights reserved.</p>
            </div>
        </div >
    )
}

export default Footer 