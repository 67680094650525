import React from "react";
import Home from "../pages/Home/Home.jsx";
import Path from "./Constant/RoutePath.jsx";
import Wallet from "../pages/Wallet/Wallet.jsx";
import FutureWallet from "../pages/FutureWallet/FutureWallet.jsx";
import ContactUs from "../pages/ContactUs/ContactUs.jsx";
import About from "../pages/About/About.jsx";
export default function PAGES() {
  return {
    PUBLIC_PAGES: [
      { path: Path.HOME, element: <Home /> },
      { path: Path.WALLET, element: <Wallet /> },
      { path: Path.ABOUT, element: <FutureWallet /> },
      { path: Path.FUTUREWALLET, element: <About /> },
      { path: Path.CONTACTUS, element: <ContactUs /> },

    ]
  };
}
