import React from "react";
import "./FutureWallet.scss";


const FutureWallet = () => {
  return (
    <>
      <div className="wallet">
        <div className="wallet_banner">
        <div class="overlay"></div>
          <div className="wallet_banner_content ">
            <h2>
              Dive into an Unparalleled Future Wallet Tailored for Lasting
              Experiences
            </h2>
            <p>
              Future Wallet isn’t just another digital solution; it's a
              game-changer in the world of crypto. It is packed with exclusive
              features and functionalities to create an unforgettable user
              experience. The platform's capabilities go beyond regular storage,
              making it a versatile and efficient solution that you can rely on.
            </p>
          </div>
        </div>

        <div className="wallet_detail ">
          <div className="">
            <h4>
              Future Wallets: Secure, Efficient & Intuitive Solution
            </h4>
            <p>
              Future Wallet is a highly efficient and secure self-custodial
              solution. It comprises outstanding features and functionalities
              tailored to meet distinct user needs. Users can swiftly access
              thousands of cryptos and dApps. Future wallet-enhanced guardrails
              protect the wallet from malicious actors. Transaction transparency
              and previews ensure better clarity and confidence within the
              digital space.
            </p>
          </div>
          <div className="wallet-detail">
            <h4>Future Wallet</h4>
            <p>
              With Future Wallet, you're not just managing your assets but
              unleashing the potential of cryptos. It's the ultimate crypto
              management solution that opens up the ever-expanding digital
              finance realm for you to explore. The platform empowers you with
              full asset control, giving you the keys to a world of complete
              financial freedom.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default FutureWallet;
